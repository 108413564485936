import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths,
      Enums.dateModes.previousTwelveMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'sales';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'service-scheduler-provider': { displayName: 'Provider', name: 'service-scheduler-provider' },
        'role': { displayName: 'Role', name: 'role' },
        'page': { displayName: 'Page', name: 'page' },
   };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Website
        'WebsiteOverview': {
          dateMode: Enums.dateModes.currentMonth,
          restrictDates: true,
          reportName: constants.reportNames.websiteOverview,
          filters: ['org'],
          defaults: ['org'],
          section: 'website',
          orgTypes: [1, 4]
      },
        // Digital Advertising v5
        'DigitalAdvertisingDashboard': {
          dateMode: Enums.dateModes.currentMonth,
          restrictDates: false,
          reportName: constants.reportNames.digitalAdvertisingDashboard,
          filters: ['org'],
          defaults: ['org'],
          section: 'digital-advertising',
          orgTypes: [1, 4]
        },
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'trade-in-provider': {
            type: 'trade-in-provider',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [2, 4, 5, 6, 8],
            selected: [
              { value: 2, display: 'Paid Search' },
              { value: 4, display: 'Paid Display' },
              { value: 5, display: 'Paid Video' },
              { value: 6, display: 'Paid Email' },
              { value: 8, display: 'Paid Social' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'chat-provider': {
            type: 'chat-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-provider': {
          type: 'digad-provider',
          available: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],
          selected: [
            { value: 1, display: 'SM360' },
            { value: 2, display: 'AutoSync' },
            { value: 3, display: 'eDealer' },
            { value: 4, display: 'Fox Dealer' },
            { value: 5, display: 'LeadBox' },
            { value: 6, display: 'Magnetis' },
            { value: 7, display: 'Vicimus' },
            { value: 8, display: 'Flex Dealer' },
            { value: 9, display: 'D2C' },
            { value: 10, display: 'AutoVerify' },
            { value: 11, display: 'CarPages' },
            { value: 12, display: 'Groupe Saillant' },
            { value: 13, display: 'Birchwood' },
            { value: 14, display: 'GoAuto' },
            { value: 15, display: 'Smedia' },
            { value: 16, display: 'Stream Companies' },
          ],
          removable: true,
          lockable: true,
          locked: false
      },
        'digital-retailing-provider': {
            type: 'digital-retailing-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'service-scheduler-provider': {
            type: 'service-scheduler-provider',
            available: [],
            selected: [
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Vehicle' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [
              { value: 1, display: 'New' },
              { value: 2, display: 'TCUV' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            selected: [],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],
            selected: [
              { value: 1, display: 'SM360' },
              { value: 2, display: 'AutoSync' },
              { value: 3, display: 'eDealer' },
              { value: 4, display: 'Fox Dealer' },
              { value: 5, display: 'LeadBox' },
              { value: 6, display: 'Magnetis' },
              { value: 7, display: 'Vicimus' },
              { value: 8, display: 'Flex Dealer' },
              { value: 9, display: 'D2C' },
              { value: 10, display: 'AutoVerify' },
              { value: 11, display: 'CarPages' },
              { value: 12, display: 'Groupe Saillant' },
              { value: 13, display: 'Birchwood' },
              { value: 14, display: 'GoAuto' },
              { value: 15, display: 'Smedia' },
              { value: 16, display: 'Stream Companies' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'role': {
            type: 'role',
            available: [],
            selected: [
          ],
            removable: false,
            lockable: true,
            locked: false
        },
        'page': {
            type: 'page',
            available: [],
            selected: [
          ],
            removable: false,
            lockable: false,
            locked: false
        },
    };
}
