export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Toyota-CA',
  hostName: 'https://analytics-staging.toyotadds.ca',
  environmentName: 'staging',
  appName: 'Toyota CA DDS Insight',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '8a23b4c2-ba57-4721-a8b8-d13e3966abf1'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#ed1c24', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://toyotaca-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://toyotaca-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://toyotaca-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/toyota/favicon.ico',
  loginRedirectUrl: 'https://tcihub.oktapreview.com/home/tcihub_ddstoyotainsight_1/0oad4ov2yyWQXv90h1d7/alnd4p31hkGZ8avEV1d7',
  logoutRedirectUrl: 'https://tcihub.oktapreview.com/home/tcihub_ddstoyotainsight_1/0oad4ov2yyWQXv90h1d7/alnd4p31hkGZ8avEV1d7',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview', 'DigitalAdvertisingDashboard']
};
