import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, ViewChild, Type, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { PanelElementResolverService } from '../panel-element-resolver.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import * as SharedServices from '../../../../_shared/services/services-index';
import { BaseComponent } from '../../../../_shared/components/base.component';
import { AppState } from '../../../../_store/app-state.model';
import { Store } from '@ngrx/store';
import { FilterActions, FilterOptions } from '../../../filter/store';
import { Enums, IEnums } from '../../../../_shared/enums/enums';
import { IRole } from '../../../models/models-index';
import { filter, map, tap, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { ReportsService } from '../../../services/api/reports.service';
import { ExportCsv } from '../../../../_shared/utilities/generate-csv';
import { AppSelectors } from '../../../../_store/selector-types';
import { convertOptionsToDictionary } from '../../panel-elements/panel-utils';
import { SelectableItem } from '../../nested-menu/nested-menu.component';
import { tableOptions } from '../../../constants/constants';
const clone = require('rfdc/default');

type SelectedConfiguration = { panelConfiguration: Models.PanelConfiguration, columnSet: string, options: Models.OptionModel[] }

@Component({
  selector: 'full-width-data-panel',
  templateUrl: './full-width-data-panel.component.html',
  styleUrls: ['./full-width-data-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FullWidthDataPanelComponent extends BaseComponent implements OnInit, OnDestroy, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  // @Output() openHelpText = new EventEmitter();
  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
  private _panelConfig: Models.Panel;
  @Input()
  set panelConfig(value: Models.Panel) {
    this._panelConfig = value;
  }
  @Input() dataSets: Models.DataSet[];
  @Input() rowPanelId: string;

  //metricSetOptions: Models.IMetricSet[];
  enums: IEnums = Enums;
  locale: string;
  subscriptions: Subscription[] = [];
  filterModel: Models.IFilterModel;
  tableTrendPlaceholder: string;
  dataTableType: string;
  get panelConfigs() { return this._panelConfig.configurations }
  selectedConfiguration$ = new BehaviorSubject<SelectedConfiguration>(null);
  get selectedConfiguration() { return this.selectedConfiguration$.value }
  settings: Models.OptionModel[] = [];
  columnSets: string[] = [];
  selectedColumnSet: Models.ColumnSet;
  private _components: Models.ElementComponent[] = [];
  translationService: Models.ITranslationService;

  panelAvailableIndexes: SelectableItem[] = [];
  get hasPanelAvailableIndexes(): boolean {
    return this.panelAvailableIndexes && this.panelAvailableIndexes.length > 0;
  }
  selectedItem: SelectableItem;

  destroySubject = new Subject<void>();
  destroyed$ = this.destroySubject.asObservable();

  requestModel: Models.ReportRequestModel = {
    orderBy: '',
    orderAscending: false,
    reportType: 'data-panel-load',
    filters: {
      startDate: new Date(2018, 7, 1),
      endDate: new Date(2018, 8, 1),
      previousStartDate: new Date(),
      previousEndDate: new Date(),
      orgCode5: null,
      orgCode4: null,
      orgCode3: null,
      orgCode2: null,
      orgCode1: null,
      dealerCode: null,
      deviceTypeId: null,
      orgLookupTypeId: 1,
    }
  };

  constructor(
    private store$: Store<AppState>,
    private localeService: SharedServices.LocaleService,
    private resolverService: PanelElementResolverService,
    private renderer2: Renderer2,
    private dataTableService: SharedServices.DataTableService,
    private filterService: SharedServices.FilterService,
    private configService: SharedServices.ReportConfigService,
    private reportService: ReportsService,
    private spinnerService: SharedServices.SpinnerService,
    private helpTextService: SharedServices.HelpTextService,
    private heatmapService: SharedServices.HeatmapService, 
    private changeDetector: ChangeDetectorRef) {
    super();
    this.tableTrendPlaceholder = 'Default';
  }

  ngOnInit(): void {
    //this.emitSelectedMetric(this.indexConfig.defaultIndexCode);
    this.filterService.filter$.subscribe(filter => {
      this.filterModel = filter;
    });

    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.generateElements().subscribe()
    )

    // initialize selectedConfiguration based on panel config
    const initialPanelConfig = this.panelConfigs[0];
    const initialColumnSet = !!initialPanelConfig.elements[0].settings.columnSetNames ? initialPanelConfig.elements[0].settings.columnSetNames[0] : null;
    this.selectedConfiguration$.next({ panelConfiguration: initialPanelConfig, columnSet: initialColumnSet, options: initialPanelConfig.options });
    this.mapPanelConfigToSelectableItems();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  resolveElement(config: SelectedConfiguration) {
    const { panelConfiguration, columnSet } = config;

    if (!columnSet || panelConfiguration.elements.length === 1)
      return panelConfiguration.elements[0];
    else
      return panelConfiguration.elements.find(e => !!columnSet && e.settings.columnSetNames.find(n => n === columnSet)) ?? panelConfiguration.elements[0];
  }

  generateElements() {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    this._components = [];

    const elementPipeline = this.selectedConfiguration$.pipe(
      filter(config => !!config),
      map(config => {
        const element = this.resolveElement(config);
        return ({ element, config })
      }),
      switchMap(({ element, config }) => this.loadDataSet(element.settings.dataSet).pipe(
        map(ds => ({ ds, config, element }))
      )),
      map(({ ds, config, element }) => {
        const dataset = clone(ds);
        viewContainerRef.clear();
        const componentFactory = this.resolverService.resolveElementComponent(element.type)
        const componentRef = viewContainerRef.createComponent<Models.ElementComponent>(componentFactory);
        componentRef.instance.dataSet = dataset;
        componentRef.instance.panelConfiguration = config.panelConfiguration;
        componentRef.instance.settings = element.settings;

        this.dataTableType = element.type;
        this._components.push(componentRef.instance);

        this.selectedColumnSet = (!!config.columnSet ? dataset.columnSets.find(cs => cs.name === config.columnSet) : null)
          ?? dataset.columnSets[0];

        if (!!element.settings.columnSetNames) {
          // we assume the data set names are being specified in the element settings
          this.columnSets = config.panelConfiguration.elements.reduce((acc, element) => {
            return element.settings.columnSetNames ? acc.concat(element.settings.columnSetNames) : acc;
          }, <string[]>[]);
        } else {
          this.columnSets = dataset.columnSets.map(cs => cs.name);
        }

        // handle options/settings
        this.settings = config.options;

        const columns = this.configureTableColumns(element.settings, dataset, this.selectedColumnSet);
        const dataTableObject = {
          columns: columns,
          data: this.toRecords(dataset, columns),
          type: this.enums.dataTableTypes.hierarchy
        }

        const isHierarchyTable = dataTableObject.data.some(row => row.expandable);
        const settingExists = this.settings.some(setting => setting.name === tableOptions.Heatmap);

        if (!isHierarchyTable && !settingExists) {
          this.settings.push({ name: tableOptions.Heatmap, label: tableOptions.Heatmap, enabled: false, exlusive: false });
        }

        this.dataTableService.updateData(dataTableObject);
        this.changeDetector.detectChanges();

        // handle settings
        // if (config.options) {
        //   this.settings = result.config.options;
        // }
      })
    );

    return elementPipeline;

    // this.renderer2.addClass(componentRef.location.nativeElement, 'ghost-panel-element');
    // this.renderer2.setAttribute(componentRef.location.nativeElement, 'style', `flex: 0 0 ${element.widthUnits * 100 / element.widthBasis}%; max-width: ${element.widthUnits * 100 / element.widthBasis}%`)
  }

  configureTableColumns(settings: Models.ElementSettings, dataset: Models.DataSet, selectedColumnSet: Models.ColumnSet): Models.IDefaultTableColumn[] {
    const columns: Models.IDefaultTableColumn[] = [];

    // huge hack
    // check to see if we're missing any columns named 'entity'
    const firstDim = dataset.columns.find(c => c.type == 'dimension');
    if (firstDim && !dataset.columns.find(c => c.name == 'entity')) {
      firstDim.name = 'entity';
    }

    // add 'show' property to each column -- This is a hack I need to clean up
    dataset.columns.forEach(c => {
      c.show = true;
    });

    const allowAllSorting = settings.options?.['allowAllSorting'] === 'true';

    // add dimension columns first
    dataset.columns.filter(c => c.type === 'dimension').forEach((col, index) => {
      const column: Models.IDefaultTableColumn = {
        columnDef: col.name,
        header: col.displayName ?? col.name,
        // header: col.header + columnSuffix,
        isMom: false,
        isYoy: false,
        show: true,
        print: col.show,
        clickable: !!false,
        columnClasses: null,
        sortable: col.sortable ?? allowAllSorting,
        canDrillthrough: col.name == 'entity' ? (val) => false : (val) => this.canDrillthrough(this.filterModel, val.entityType),
        formatter: null,
        printFormatter: null
      };
      columns.push(column);
    });

    //const useColumnSet = selectedColumnSet ? selectedColumnSet : dataset.columnSets?.[0];
    const metricColumnsToAdd = this.selectedColumnSet ? this.selectedColumnSet.columnNames : dataset.columns.filter(c => c.type === 'metric').map(c => c.name);

    // add metric columns
    metricColumnsToAdd.forEach((col, index) => {
      const column = dataset.columns.find(c => c.name == col);
      if (column) {
        const col: Models.IDefaultTableColumn = {
          columnDef: column.name,
          header: column.displayName ?? column.name,
          isMom: false,
          isYoy: false,
          show: true,
          print: column.show,
          clickable: !!false,
          columnClasses: null,
          formatter: null,
          printFormatter: null,
          sortable: column.sortable ?? allowAllSorting,
          valueInverted: column.valueInverted
        };
        columns.push(col);
      }
    });

    return columns;
  }
  removeAllDataSets() {
    this.dataSets = [];
  }
  loadDataSet(dataSetName: string): Observable<Models.DataSet> {
    // Check if the dataset with the given name exists in this.dataSets
    const existingDataSet = this.dataSets.find(
      (dataSet) => dataSet.name === dataSetName
    );

    // If the dataset exists, return it as an Observable
    if (existingDataSet) {
      return of(existingDataSet);
    }

    return this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      switchMap(routeData =>
        iif(
          () => !!routeData.useV5Filters,
          this.getReportViewDataSet(routeData.reportName, dataSetName),
          this.getLegacyDataSet(dataSetName)
        )
      )
    );
  }

  getReportViewDataSet(reportName: string, dataSetName: string): Observable<Models.DataSet> {
    this.spinnerService.show();
    return this.filterService.getReportViewFilterRequestModel(reportName).pipe(
      takeUntil(this.destroyed$),
      map(requestModel => ({ ...requestModel, dataSets: [dataSetName], options: convertOptionsToDictionary(this.settings) })),
      switchMap(requestModel => this.reportService.getDataSet(requestModel)),
      map((dataSets: Models.DataSet[]) => {
        if (!dataSets || dataSets.length == 0)
          throw new Error('No data sets returned from the API');

        // dataSets.forEach(ds => {
        //   ds.columns.forEach(c => {
        //     const displayName = c.displayName ?? c.name
        //     c.displayName = c.type === 'metric'
        //       ? this.translationService?.getMetricNameTranslation(c.name, displayName, this.locale) ?? displayName
        //       : this.translationService?.getLabelTranslation(displayName, this.locale) ?? displayName
        //   });
        // })
        this.dataSets.push(dataSets[0]);

        return dataSets[0];
      }),
      tap({
        next: _ => this.spinnerService.hide(),
        error: err => {
          this.spinnerService.hide();
          appInsights.trackException(err);
          // Rethrow the error to ensure it's propagated up the stream
          throw err;
        }
      }),
      catchError(err => {
        // Handle the error and return a safe value or an empty Observable
        // Example: return of({} as Models.DataSet);
        // Or rethrow the error if you want to handle it further up the chain
        return throwError(() => err);
      })
    );
  }

  getLegacyDataSet(dataSetName: string): Observable<Models.DataSet> {
    return combineLatest([this.filterService.filter$])
      .pipe(
        tap(_ => this.spinnerService.show()),
        map(([updatedFilter]) => {
          const requestModel = { ...this.requestModel, filters: { ...updatedFilter }, dataSets: [dataSetName], options: convertOptionsToDictionary(this.settings) };
          return requestModel;
        }),
        switchMap(requestModel => this.reportService.getDataSet(requestModel)),
        map((dataSets: Models.DataSet[]) => {
          if (!dataSets || dataSets.length == 0)
            throw new Error('No data sets returned from the API');

          dataSets.forEach(ds => ds.columns.forEach(c => {
            const displayName = c.displayName ?? c.name
            c.displayName = c.type === 'metric'
              ? this.translationService?.getMetricNameTranslation(c.name, displayName, this.locale) ?? displayName
              : this.translationService?.getLabelTranslation(displayName, this.locale) ?? displayName
          }
          ));

          this.dataSets.push(dataSets[0]);

          return dataSets[0];
        }),
        tap(_ => this.spinnerService.hide())
      );
  }

  toRecords(dataSet: Models.DataSet, columns: Models.IDefaultTableColumn[]): any[] {
    const columnNames = dataSet.columns.map(c => c.name);
    const records = [];

    dataSet.rows.forEach(row => {
      const record = {};
      let foundShow = false;

      columnNames.forEach((name, i) => {
        const label = row[i]?.label;
        const value = row[i]?.value;

        if (name === 'show') foundShow = true;

        record[name] = label ?? value;
      });

      if (!foundShow) record['show'] = true;

      records.push(record);
    });

    // Apply heatmap colors only for tables that are not expandable
    if (!records.some(row => row.expandable)) {
      this.assignHeatmapColorToRecords(records, dataSet, columns, columnNames);
    }

    // Apply grey background color to the total row if totalRowPosition is set
    const totalRowPosition = this.resolveElement(this.selectedConfiguration).settings.options?.['totalRowPosition'];
    if (totalRowPosition) {
      // Apply grey background color to the existing total row (first or last)
      this.applyGreyBackgroundToTotalRow(records, totalRowPosition);
    }

    return records;
  }
  
  changeConfiguration($event: MatSelectChange) {
    // This is a necessary check for when we apply rule-based column set selection.  On load it works, but when
    // switching between indexes if we set the columnSet to null it will default to the first column set in the list
    // and ignore the rules.
    const defaultColumnSet = ($event.value.elements[0].settings.columnSetNames || []).length > 0 
      ? $event.value.elements[0].settings.columnSetNames[0] 
      : null;
      
    this.selectedConfiguration$.next({ panelConfiguration: $event.value, columnSet: defaultColumnSet, options: $event.value.options });
  }


  columnSetChanged($event): void {
    this.selectedConfiguration$.next({ ...this.selectedConfiguration, columnSet: $event.value });
  }

  private canDrillthrough(filterModel: Models.IFilterModel, level: string): boolean {
    const roleConfig = this.configService.roleConfig;
    const areSame = (s1: string, s2: string) => (s1 || '').toLowerCase() === (s2 || '').toLowerCase();

    // Determine if the user is in a specific role
    const isRole = (roles: IRole[]) => roles.map(r => r.name.toLowerCase()).indexOf((filterModel.roleLevel || '').toLowerCase()) != -1;

    // Determine if the role matches the requested report org level
    const isLevel = (orgLevel: string) => areSame(level, orgLevel);

    if (isRole(roleConfig.dealerRoles)) {
      return false;
    } else if (isRole(roleConfig.org1Roles)) {
      return isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
    } else if (isRole(roleConfig.org2Roles)) {
      return isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
        || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
    } else if (isRole(roleConfig.org3Roles)) {
      return isLevel(this.enums.hierarchicalEntityTypes.orgcode2.type)
        || isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
        || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
    } else if (isRole(roleConfig.org4Roles)) {
      return isLevel(this.enums.hierarchicalEntityTypes.orgcode3.type)
        || isLevel(this.enums.hierarchicalEntityTypes.orgcode2.type)
        || isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
        || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
    } else if (isRole(roleConfig.org5Roles)) {
      return isLevel(this.enums.hierarchicalEntityTypes.orgcode4.type)
        || isLevel(this.enums.hierarchicalEntityTypes.orgcode3.type)
        || isLevel(this.enums.hierarchicalEntityTypes.orgcode2.type)
        || isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
        || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
    } else if (isRole(roleConfig.systemAdministratorRoles) || isRole(roleConfig.corporateRoles)) {
      return true;
    } else {
      // we can't infer role appropriately so don't allow drill-through
      return false;
    }
  }

  applySetting($event, setting: Models.OptionModel) {
    setting.enabled = $event.checked;

    // Notify child components of the updated settings
    this._components.forEach(component => {
      component.options = [...this.settings];  // Make sure a new reference is passed, triggering change detection
      this.changeDetector.detectChanges();
    });
  
    if (setting.name === tableOptions.Heatmap) {
      return;
    }

    let currentSettings = this.selectedConfiguration.options ?? [];

    // add the setting if it isn't already in the list, otherwise update it
    if (!currentSettings.some(s => s.name == setting.name)) {
      currentSettings.push(setting);
    } else {
      currentSettings[currentSettings.findIndex(s => s.name == setting.name)] = setting;
    }

    this.removeAllDataSets();

    //this.settingsChangedSubject.next(this.selectedSettings);
    this.selectedConfiguration$.next({ ...this.selectedConfiguration, options: currentSettings });
  }

  isSettingSelected(setting: Models.OptionModel): boolean {
    return !!this.selectedConfiguration.options?.some(s => s.name == setting.name && s.enabled);
  }

  exportToExcel(selectedConfiguration: SelectedConfiguration) {
    const element = this.resolveElement(selectedConfiguration);
    const panelConfig = selectedConfiguration.panelConfiguration;

    // get dataset
    const dataSet = this.dataSets.find(ds => ds.name == element.settings.dataSet);

    // HACK - we're saying for now all 'meta' columns are not exportable
    const dimensions = [];
    const metrics = [];


    // build up dimensions/meta
    dataSet.columns.forEach((c, index) => {
      if (c.export != false && c.type != 'metric') {
        const displayName = c.displayName ?? c.name
        const column = {
          name: this.translationService?.getLabelTranslation(displayName, this.locale) ?? displayName,
          index: index,
          isMetric: false
        };
        dimensions.push(column)
      }
    });

    // build up metrics based on the order in this.selectedColumnSet.columns
    const columnNames = this.selectedColumnSet?.columnNames ?? dataSet.columns.map(c => c.name);
    columnNames.forEach(cn => {
      const c = dataSet.columns.find(c => c.name === cn);
      const cIndex = dataSet.columns.findIndex(c => c.name === cn);
      if (c.export != false && c.type === 'metric') {
        const column = {
          name: c.displayName ?? c.name,
          index: cIndex,
          isMetric: true
        };
        metrics.push(column);
      }
    });

    // Merge the two arrays
    const columns = dimensions.concat(metrics);

    const exportCols = {
      names: columns.map(c => c.name),
      indexes: columns.reduce((acc, c) => ({ ...acc, [c.name]: c.index }), {}),
      metrics: columns.reduce((acc, c) => ({ ...acc, [c.name]: c.isMetric }), {})
    };

    const { names: colNames, indexes: colIndexes, metrics: colMetrics } = exportCols;


    const rows = dataSet.rows.map(row => {
      return colNames.map(name => {
        const colIndex = colIndexes[name];
        const value = row[colIndex]?.value?.toString() || '';
        const label = row[colIndex]?.label?.toString() || '';
        const rowValue = label || value;
    
        let metricValue = colMetrics[name] 
          ? label 
          : this.translationService?.getLabelTranslation(value || label, this.locale) || rowValue;
    
        if (metricValue === value) {
          // Use label if translation failed or was not needed
          metricValue = rowValue;
        }
    
        return metricValue;
      });
    });
    

    const panelLabel = panelConfig.label ?? panelConfig.name;
    const fileName = (panelLabel).replace(/-| /g, '');

    const exportData: string[][] = [[panelLabel]];
    exportData.push(colNames);
    exportData.push(...rows);

    new ExportCsv(exportData, fileName);

  }

  openHelpTextClicked(): void {
    this.helpTextService.openHelp(this.selectedConfiguration.panelConfiguration.helpTextKey, this.selectedConfiguration.panelConfiguration.helpTextTitle);
  }

  private mapPanelConfigToSelectableItems(): void {
    if(!this._panelConfig.indexOrdering?.length) return;
    // Define a recursive function to map each IndexOrderingItem to a SelectableItem
    const mapItems = (items: any[]): SelectableItem[] => {
      return items.map(item => {
        const children = item.children ? mapItems(item.children) : [];
        return {
          value: item.value,
          label: item.label ?? item.value,
          children: children?.length > 0 ? children : undefined // Only add children property if it's not empty
        } as SelectableItem;
      });
    };
  
    // Use the recursive function to transform the entire indexOrdering array
    this.panelAvailableIndexes = mapItems(this._panelConfig.indexOrdering);

    // Define a recursive function to find the first item with no children
    const findFirstItemWithoutChildren = (items: SelectableItem[]): SelectableItem | undefined => {
      for (let item of items) {
        if (!item.children || item.children.length === 0) {
          return item;
        }
        const childItem = findFirstItemWithoutChildren(item.children);
        if (childItem) {
          return childItem;
        }
      }
      return undefined;
    };

    // Find and set the selected item
    this.selectedItem = findFirstItemWithoutChildren(this.panelAvailableIndexes)
      ?? this.panelAvailableIndexes[0];
  }
  
  onSelectionChange(item: SelectableItem) {
    this.selectedItem = item;
    // find the panel configuration that matches the selected item value
    const panelConfiguration = this.panelConfigs.find(config => config.name === item.value);
    this.selectedConfiguration$.next({ panelConfiguration: panelConfiguration, columnSet: null, options: panelConfiguration.options });
  }

  assignHeatmapColorToRecords(records: any[], dataSet: Models.DataSet, columns: Models.IDefaultTableColumn[], columnNames: string[]): any[] {
    const totalRowPosition = this.resolveElement(this.selectedConfiguration).settings.options?.['totalRowPosition'];
  
    // Handle valid records and skip total row in the dataset if required
    let validRowIndices = Array.from(dataSet.rows.keys());  // Get all indices initially
  
    if (totalRowPosition === 'first') {
      // Skip the first row if it's a total row
      validRowIndices = validRowIndices.slice(1);
    } else if (totalRowPosition === 'last') {
      // Skip the last row if it's a total row
      validRowIndices = validRowIndices.slice(0, -1);
    }
  
    columns.forEach(column => {
      // Extract raw values directly from dataSet using valid row indices
      const values: number[] = validRowIndices
        .map(rowIndex => dataSet.rows[rowIndex][columnNames.indexOf(column.columnDef)]?.value)
        .filter(value => typeof value === 'number') as number[];
  
      // If no valid numeric values exist, skip heatmap assignment for this column
      if (values.length === 0) {
        return;
      }
  
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);
  
      // Normalize values to range between 0 and 1
      const normalizedValues = values.map(value => (value - minValue) / (maxValue - minValue));
  
      // Get heatmap colors for normalized values
      const isInverted = column.valueInverted ?? false;
      const colors = this.heatmapService.getHeatmapColors(normalizedValues, isInverted);
  
      // Assign colors back to records for each valid row
      let valueIndex = 0;
      validRowIndices.forEach(rowIndex => {
        const rawValue = dataSet.rows[rowIndex][columnNames.indexOf(column.columnDef)]?.value;
  
        // Ensure we are assigning color only to numeric values
        if (typeof rawValue === 'number') {
          records[rowIndex][`${column.columnDef}_heatmap_color`] = colors[valueIndex];
          valueIndex++;  // Increment the index to map the next value to the next color
        }
      });
    });
  
    return records;
  }

  applyGreyBackgroundToTotalRow(records: any[], totalRowPosition: string): void {
    let totalRow;
    if (totalRowPosition === 'first') {
      totalRow = records[0];  // The first row is the total row
    } else if (totalRowPosition === 'last') {
      totalRow = records[records.length - 1];  // The last row is the total row
    }
  
    if (totalRow) {
      // Apply grey background to every field in the total row
      Object.keys(totalRow).forEach(key => {
        totalRow[`${key}_heatmap_color`] = '#f0f0f0';  // Set background color to soft grey
      });
    }
  }
   
}
