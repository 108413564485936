import { Injectable } from '@angular/core';

import * as Models from '../../../models/models-index';
import { FormattingService } from '../../formatting/formatting.service';
import { IEnums, Enums } from '../../../enums/enums';
import { formatDate } from '@angular/common';
import * as constants from '../../../constants/constants';

// Inventory Reports
import { InventoryOverviewConfig } from '../../../../_config/reports/inventory/inventory-overview/inventory-overview.config';

// DigAd Reports
import { DigAdOverviewConfig } from '../../../../_config/reports/digAd/digAd-overview/digAd-overview.config';
import { DigAdScorecardConfig } from '../../../../_config/reports/digAd/digAd-scorecard/digAd-scorecard.config';
import { DigAdSummaryConfig } from '../../../../_config/reports/digAd/digAd-summary/digAd-summary.config';
import { DigAdProviderConfig } from '../../../../_config/reports/digAd/digAd-provider/digAd-provider.config';
import { DigAdChannelConfig } from '../../../../_config/reports/digAd/digAd-channel/digAd-channel.config';
import { DigAdProviderScorecardConfig } from '../../../../_config/reports/digAd/digAd-provider-scorecard/digAd-provider-scorecard.config';

// Lead Reports
import { LeadsEventConfig } from '../../../../_config/reports/leads/leads-event/leads-event.config';
import { LeadsFacebookConfig } from '../../../../_config/reports/leads/leads-facebook/leads-facebook.config';
import { LeadsOverviewConfig } from '../../../../_config/reports/leads/leads-overview/leads-overview.config';
import { LeadsSummaryConfig } from '../../../../_config/reports/leads/leads-summary/leads-summary.config';
import { LeadTransactionsConfig } from '../../../../_config/reports/leads/lead-transactions/lead-transactions.config';
import { SourceSummaryConfig } from '../../../../_config/reports/leads/source-summary/source-summary.config';
import { LeadsVehicleConfig } from '../../../../_config/reports/leads/leads-vehicle/leads-vehicle.config';

// Website Reports
import { WebsiteCertifiedChatConfig } from '../../../../_config/reports/website/website-certifiedchat/website-certifiedchat.config';
import { WebsiteAfterSalesConfig } from '../../../../_config/reports/website/website-aftersales/website-aftersales.config';
import { WebsiteLandingPageConfig } from '../../../../_config/reports/website/website-landingpage/website-landingpage.config';
import { WebsiteOverviewConfig } from '../../../../_config/reports/website/website-overview/website-overview.config';
import { WebsiteSummaryConfig } from '../../../../_config/reports/website/website-summary/website-summary.config';
import { WebsiteProviderConfig } from '../../../../_config/reports/website/website-provider/website-provider.config';
import { WebsiteProviderScorecardConfig } from '../../../../_config/reports/website/website-provider-scorecard/website-provider-scorecard.config';
import { WebsiteReferrerConfig } from '../../../../_config/reports/website/website-referrer/website-referrer.config';
import { WebsiteScorecardConfig } from '../../../../_config/reports/website/website-scorecard/website-scorecard.config';
import { WebsiteVehicleConfig } from '../../../../_config/reports/website/website-vehicle/website-vehicle.config';

// Sales Reports
import { SalesOverviewConfig } from '../../../../_config/reports/sales/sales-overview/sales-overview.config';
import { SalesSummaryConfig } from '../../../../_config/reports/sales/sales-summary/sales-summary.config';
import { SalesVehicleConfig } from '../../../../_config/reports/sales/sales-vehicle/sales-vehicle.config';
import { SalesSourceConfig } from '../../../../_config/reports/sales/sales-source/sales-source.config';
import { SalesForecastingConfig } from '../../../../_config/reports/sales/sales-forecasting/sales-forecasting.config';

// Provider Tools Reports
import { ChatOverviewConfig } from '../../../../_config/reports/provider-tools/chat/chat-overview.config';
import { ChatProviderScorecardConfig } from '../../../../_config/reports/provider-tools/chat-provider-scorecard/chat-provider-scorecard.config';
import { DigitalRetailingOverviewConfig } from '../../../../_config/reports/provider-tools/digital-retailing/digital-retailing-overview.config';
import { DigitalRetailingInStoreConfig } from '../../../../_config/reports/provider-tools/digital-retailing/digital-retailing-in-store/digital-retailing-in-store.config';
import { ReputationManagementOverviewConfig } from '../../../../_config/reports/provider-tools/reputation-management/reputation-management-overview.config';
import { SocialMediaOverviewConfig } from '../../../../_config/reports/provider-tools/social-media/social-media-overview.config';
import { IPreviousDateRangeDisplay, IRole, IRoleConfig, ReportResponseModel } from '../../../models/models-index';
import { ServiceSchedulerOverviewConfig } from '../../../../_config/reports/provider-tools/service-scheduler/service-scheduler-overview.config';
import { TradeInOverviewConfig } from '../../../../_config/reports/provider-tools/trade-in/trade-in-overview.config';
import { TradeInProviderScorecardConfig } from '../../../../_config/reports/provider-tools/trade-in-provider-scorecard/trade-in-provider-scorecard.config';
import { ProcessPerformanceConfig } from '../../../../_config/reports/leads/process-performance/process-performance.config';

//Dealer Resources
import { DealerResourcesConfig } from '../../../../_config/reports/dealer-resources/dealer-resources/dealer-resources.config';

// Role Config
import { RoleConfig } from '../../../../_config/role/role.config';

//Hospitality Website Reports
import { HospitalityWebsiteOverviewConfig } from '../../../../_config/reports/hospitality-website/hospitality-website-overview/hospitality-website-overview.config';
import { HospitalityWebsiteSummaryConfig } from '../../../../_config/reports/hospitality-website/hospitality-website-summary/hospitality-website-summary.config';
import { UserActivityOverviewConfig } from '../../../../_config/reports/user-activity/overview/user-activity-overview.config';


@Injectable()
export class ReportConfigService {
    enums: IEnums = Enums;

    roleConfig: IRoleConfig = new RoleConfig;

    // Inventory Reports
    inventoryOverviewConfig: Models.IInventoryOverviewConfig = new InventoryOverviewConfig;

    // Lead Reports
    leadsEventConfig: Models.ILeadsEventConfig = new LeadsEventConfig;
    leadsFacebookConfig: Models.ILeadsFacebookConfig = new LeadsFacebookConfig;
    leadsOverviewConfig: Models.ILeadsOverviewConfig = new LeadsOverviewConfig;
    leadsSummaryConfig: Models.ILeadsSummaryConfig = new LeadsSummaryConfig;
    leadTransactionsConfig: Models.ILeadTransactionsConfig = new LeadTransactionsConfig;
    sourceSummaryConfig: Models.ISourceSummaryConfig = new SourceSummaryConfig;
    leadsVehicleConfig: Models.ILeadsVehicleConfig = new LeadsVehicleConfig;
    processPerformanceConfig: Models.IProcessPerformanceConfig = new ProcessPerformanceConfig;

    // Sales Reports
    salesOverviewConfig: Models.ISalesOverviewConfig = new SalesOverviewConfig;
    salesSummaryConfig: Models.ISalesSummaryConfig = new SalesSummaryConfig;
    salesVehicleConfig: Models.ISalesVehicleConfig = new SalesVehicleConfig;
    salesSourceConfig: Models.ISalesSourceConfig = new SalesSourceConfig;
    salesForecastingConfig: Models.ISalesForecastingConfig = new SalesForecastingConfig;

    // DigAd Reports
    digAdOverviewConfig: Models.IDigAdOverviewConfig = new DigAdOverviewConfig;
    digAdScorecardConfig: Models.IDigAdScorecardConfig = new DigAdScorecardConfig;
    digAdSummaryConfig: Models.IDigAdSummaryConfig = new DigAdSummaryConfig;
    digAdProviderConfig: Models.IDigAdProviderConfig = new DigAdProviderConfig;
    digAdChannelConfig: Models.IDigAdChannelConfig = new DigAdChannelConfig;
    digAdProviderScorecardConfig: Models.IDigAdProviderScorecardConfig = new DigAdProviderScorecardConfig;

    // Website Reports
    websiteAfterSalesConfig: Models.IWebsiteAfterSalesConfig = new WebsiteAfterSalesConfig;
    websiteCertifiedChatConfig: Models.IWebsiteCertifiedChatConfig = new WebsiteCertifiedChatConfig;
    websiteLandingPageConfig: Models.IWebsiteLandingPageConfig = new WebsiteLandingPageConfig;
    websiteOverviewConfig: Models.IWebsiteOverviewConfig = new WebsiteOverviewConfig;
    websiteProviderConfig: Models.IWebsiteProviderConfig = new WebsiteProviderConfig;
    websiteProviderScorecardConfig: Models.IWebsiteProviderScorecardConfig = new WebsiteProviderScorecardConfig;
    websiteReferrerConfig: Models.IWebsiteReferrerConfig = new WebsiteReferrerConfig;
    websiteScorecardConfig: Models.IWebsiteScorecardConfig = new WebsiteScorecardConfig;
    websiteSummaryConfig: Models.IWebsiteSummaryConfig = new WebsiteSummaryConfig;
    websiteVehicleConfig: Models.IWebsiteVehicleConfig = new WebsiteVehicleConfig;


    // Hospitality Website Reports
    hospitalityWebsiteOverviewConfig: Models.IHospitalityWebsiteOverviewConfig = new HospitalityWebsiteOverviewConfig;
    hospitalityWebsiteSummaryConfig: Models.IHospitalityWebsiteSummaryConfig = new HospitalityWebsiteSummaryConfig;

    // Provider Tools
    chatOverviewConfig: Models.IChatOverviewConfig = new ChatOverviewConfig;
    chatProviderScorecardConfig: Models.IChatProviderScorecardConfig = new ChatProviderScorecardConfig;
    digitalRetailingOverviewConfig: Models.IDigitalRetailingOverviewConfig = new DigitalRetailingOverviewConfig;
    digitalRetailingInStoreConfig: Models.IDigitalRetailingInStoreConfig = new DigitalRetailingInStoreConfig;
    tradeInOverviewConfig: Models.ITradeInOverviewConfig = new TradeInOverviewConfig;
    tradeInProviderScorecardConfig: Models.ITradeInProviderScorecardConfig = new TradeInProviderScorecardConfig;
    reputationManagementOverviewConfig: Models.IReputationManagementOverviewConfig = new ReputationManagementOverviewConfig;
    socialMediaOverviewConfig: Models.ISocialMediaOverviewConfig = new SocialMediaOverviewConfig;
    serviceSchedulerOverviewConfig: Models.IServiceSchedulerOverviewConfig = new ServiceSchedulerOverviewConfig;

    //Dealer Resources
    dealerResourcesConfig: Models.IDealerResourcesConfig = new DealerResourcesConfig;

    //User Activity
    userActivityConfig: Models.IUserActivityOverviewConfig = new UserActivityOverviewConfig

  constructor(private formattingService: FormattingService) { }

    configureDataTableColumns(
        filterModel: Models.IFilterModel,
        columnsConfig: Models.IDefaultTableColumnConfig[],
        createPreviousDateRangeColumns: boolean = false,
        metricTranslator?: Function,
        labelTranslator?: Function,
        locale?: string,
        displayOverrides?: IPreviousDateRangeDisplay): Models.IDefaultTableColumn[] {

        const columnSuffix = this.getLookbackSuffix(filterModel.startDate, this.leadsOverviewConfig.lookbackMonths, locale);

        const columns: Models.IDefaultTableColumn[] = [];

        columnsConfig.forEach(col => {
            const column: Models.IDefaultTableColumn = {
                columnDef: col.columnDef,
                header: !!metricTranslator ? metricTranslator(col.columnDef, col.header + columnSuffix) : col.header + columnSuffix,
                // header: col.header + columnSuffix,
                isMom: col.isMom,
                isYoy: col.isYoy,
                show: col.show,
                print: col.print,
                clickable: !!col.clickable,
                columnClasses: col.columnClasses,
                sortable: col.sortable,
                valueInverted: col.valueInverted,
                canDrillthrough: !col.clickable ? (val) => false : (val) => this.canDrillthrough(filterModel, val.entityType),
                formatter: this.formattingService.getFormatter(col.metricFormatKey, locale),
                printFormatter: this.formattingService.getFormatter(col.printFormatKey ?? col.metricFormatKey, locale)
            };
            columns.push(column);
            if (!!createPreviousDateRangeColumns && col.hasComparisonMetrics) {
                columns.push.apply(columns, this.spawnPreviousDateRangeColumns(col, columnSuffix, metricTranslator, labelTranslator, locale, displayOverrides));
            }
        });

        return columns;
    }

    private canDrillthrough(filterModel: Models.IFilterModel, level: string): boolean {
        const areSame = (s1: string, s2: string) => (s1 || '').toLowerCase() === (s2 || '').toLowerCase();

        // Determine if the user is in a specific role
        const isRole = (roles: IRole[]) => roles.map(r => r.name.toLowerCase()).indexOf((filterModel.roleLevel || '').toLowerCase()) != -1;

        // Determine if the role matches the requested report org level
        const isLevel = (orgLevel: string) => areSame(level, orgLevel);

        if (isRole(this.roleConfig.dealerRoles)) {
            return false;
        } else if (isRole(this.roleConfig.org1Roles)) {
            return isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
        } else if (isRole(this.roleConfig.org2Roles)) {
            return isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
                || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
        } else if (isRole(this.roleConfig.org3Roles)) {
            return isLevel(this.enums.hierarchicalEntityTypes.orgcode2.type)
                || isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
                || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
        } else if (isRole(this.roleConfig.org4Roles)) {
          return isLevel(this.enums.hierarchicalEntityTypes.orgcode3.type)
            || isLevel(this.enums.hierarchicalEntityTypes.orgcode2.type)
            || isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
            || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
        } else if (isRole(this.roleConfig.org5Roles)) {
          return isLevel(this.enums.hierarchicalEntityTypes.orgcode4.type)
            || isLevel(this.enums.hierarchicalEntityTypes.orgcode3.type)
            || isLevel(this.enums.hierarchicalEntityTypes.orgcode2.type)
            || isLevel(this.enums.hierarchicalEntityTypes.orgcode1.type)
            || isLevel(this.enums.hierarchicalEntityTypes.dealer.type);
        } else if (isRole(this.roleConfig.systemAdministratorRoles) || isRole(this.roleConfig.corporateRoles)) {
            return true;
        } else {
            // we can't infer role appropriately so don't allow drill-through
            return false;
        }
    }

    getLookbackSuffix(startDate: Date, lookbackMonths: number, locale: string): string {
        if (!!startDate || !!lookbackMonths) {
            return '';
        } else {
            const d = new Date(startDate);
            d.setMonth(d.getMonth() - lookbackMonths);
            const suffix = '(' + formatDate(d, 'MMM', locale) + ')';
            return suffix;
        }
    }

    // This function will generate the Prev. period and comparison columns so we don't have to put them all in the config!
    private spawnPreviousDateRangeColumns(col: Models.IDefaultTableColumnConfig, columnSuffix: string, translator?: Function, labelTranslator?, locale?: string, displayOverrides?: Models.IPreviousDateRangeDisplay): Models.IDefaultTableColumn[] {
      const prevMonthDisplay = displayOverrides?.previousMonthDisplay ?? 'Prev. Month'
      const momDisplay = displayOverrides?.momDisplay ?? 'MOM'
      let columns: Models.IDefaultTableColumn[] = [
            {
                columnDef: `${col.columnDef}PreviousMTD`,
                header: !!translator ? translator( `${col.columnDef}PreviousMTD`, `${prevMonthDisplay} ${col.header + columnSuffix}`) : `${prevMonthDisplay} ${col.header + columnSuffix}`,
                isMom: true,
                isYoy: false,
                show: false,
                clickable: false,
                sortable: col.sortable,
                formatter: this.formattingService.getFormatter(col.metricFormatKey, locale),
                printFormatter: this.formattingService.getFormatter(col.printFormatKey ?? col.metricFormatKey, locale)
            },
            {
                columnDef: `${col.columnDef}MOM`,
                header: !!translator ? translator(`${col.columnDef}MOM`, `${col.header} ${momDisplay} ${columnSuffix}`) : `${col.header} ${momDisplay} ${columnSuffix}`,
                isMom: true,
                isYoy: false,
                show: false,
                clickable: false,
                sortable: col.sortable,
                formatter: this.formattingService.getFormatter(col.comparisonFormatKey || constants.formatKeys.percentageOneDecimal, locale),
                printFormatter: this.formattingService.getFormatter(constants.formatKeys.percentageOneDecimal, locale)
            },
            {
                columnDef: `${col.columnDef}PreviousYear`,
                header: !!translator ? translator(`${col.columnDef}PreviousYear`, `Prev. Year ${col.header + columnSuffix}`) : `Prev. Year ${col.header + columnSuffix}`,
                isMom: false,
                isYoy: true,
                show: false,
                clickable: false,
                sortable: col.sortable,
                formatter: this.formattingService.getFormatter(col.metricFormatKey, locale),
                printFormatter: this.formattingService.getFormatter(col.printFormatKey ?? col.metricFormatKey, locale)
            },
            {
                columnDef: `${col.columnDef}YOY`,
                header: !!translator ? translator(`${col.columnDef}YOY`, `${col.header} YOY ${columnSuffix}`) : `${col.header} YOY ${columnSuffix}`,
                isMom: false,
                isYoy: true,
                show: false,
                clickable: false,
                sortable: col.sortable,
                formatter: this.formattingService.getFormatter(col.comparisonFormatKey || constants.formatKeys.percentageOneDecimal, locale),
                printFormatter: this.formattingService.getFormatter(constants.formatKeys.percentageOneDecimal, locale)
            },
        ];

        return columns;

    }

    roleCanExport(roleId: string, roleIdsWithExportPermission: number[]) : boolean {
      if(!roleIdsWithExportPermission){
        return true;
      }
      else
      {
        return roleIdsWithExportPermission.includes(parseInt(roleId));
      }
    }
}
