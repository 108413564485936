import { Injectable } from '@angular/core';
import * as chroma from 'chroma-js';

@Injectable({
  providedIn: 'root'
})
export class HeatmapService {
  getHeatmapColors(values: number[], isInverted: boolean = false): string[] {
    const totalValues = values.length;

    // Generate a color scale dynamically based on the number of cells in the column
    const colorScale = chroma.scale(['#ff6666', '#FFFF99', '#66cc66'])  // Red, Yellow, Green
      .mode('lab')  // Use LAB color space for smoother transitions
      .colors(totalValues);  // Generate the exact number of colors required

    // Rank the values from smallest to largest
    const sortedValues = values
      .map((value, index) => ({ value, index }))  // Keep track of original indexes
      .sort((a, b) => a.value - b.value);  // Sort by value

    // Map of original index to the sorted rank for color assignment
    const colorMap: string[] = new Array(values.length);
    sortedValues.forEach(({ value, index }, rank) => {
      // If inverted, reverse the color scale assignment
      const adjustedRank = isInverted ? totalValues - 1 - rank : rank;
      colorMap[index] = colorScale[adjustedRank];
    });

    return colorMap;
  }
}
