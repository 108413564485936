import { Component, ViewChild, Input, OnChanges, Output, EventEmitter, OnInit, DoCheck, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataTableService, FilterService, LocaleService } from '../../../services/services-index';
import { Subscription } from 'rxjs';
import { Enums } from '../../../enums/enums';
import { ExportCsv } from '../../../../_shared/utilities/generate-csv';
import { OrgFilterValue } from '../../../filter/filter.model';
import { ExpandableTable } from '../../../dataTable/expandableTable';
import { IDefaultTableColumn, IExpandableTable } from '../../../models/models';
import * as SharedServices from '../../../services/services-index';
import { ConfigurationService } from '../../../services/config/config.service';
import * as Models from '../../../models/models-index';
import { MatSort, Sort } from '@angular/material/sort';
import { ComparerService } from '../../../services/utils/comparer.service';
import { tableOptions } from '../../../constants/constants';

@Component({
  selector: 'hierarchy-data-table-v5',
  templateUrl: 'hierarchy-data-table.component.html',
  styleUrls: ['./hierarchy-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyDataTableV5Component extends ExpandableTable implements Models.ElementComponent, IExpandableTable, OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() columns: any[] = [];
  @Input() panelConfiguration: Models.PanelConfiguration;
  @Input() settings: Models.ElementSettings;
  private _dataSet: Models.DataSet;
  private _records: any[];
  private _options?: Models.OptionModel[];

  @Input()
  set options(value: Models.OptionModel[] | undefined) {
    this._options = value;
    this.updateHeatmapState();
  }

  get options(): Models.OptionModel[] | undefined {
    return this._options;
  }

  private heatmapEnabled: boolean = false;

  @Input()
  set dataSet(value: Models.DataSet)
  {
    this._dataSet = value;
    //this._records = this.toRecords(this._dataSet);
    //this.dataSource = new MatTableDataSource(this._records.filter(row => row.show));
  }
  @Output() drillThroughChange = new EventEmitter<OrgFilterValue>();
  get columnNames() { return this.columns.map(c => c.columnDef); }
  displayedColumns: string[];
  filterModel: Models.IFilterModel;
  expanded: boolean = false;
  receivedData: any;
  showMom = false;
  showYoy = false;
  dataSource: MatTableDataSource<any>;
  filterBreadcrumbsForExport: string[];
  subscriptions: Subscription[] = [];
  locale: string;
  orgLookupTypeId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  pageIndex = 0;
  pageSize = 25;
  length;

  constructor(
    private dataTableService: DataTableService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService,
    private configService: ConfigurationService,
    private localeService: LocaleService,
    private router: Router,
    private sharedTranslationService: SharedServices.SharedTranslationService,
    private readonly comparerService: ComparerService
  ) {
    super();

  }

  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);

  toggleExpanded(expanded?: boolean) {
    this.expanded = (expanded || !this.expanded);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.expanded = false; //this.dataTableService.heightExpanded;
    this.subscriptions.push(
      this.filterService.filter$.subscribe(filter => {
        this.filterModel = filter;
      }),
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.filterService.filter$.subscribe(olt => this.orgLookupTypeId = olt.orgLookupTypeId ?? 1),
      this.dataTableService.toggleHierarchyV5TableExpando$.subscribe(settings => {
        if (settings.expandType === 'height') {
          this.toggleExpanded(settings.expanded)
        }
      }),

      this.dataTableService.dataUpdated$.subscribe(updatedData => {
        // First, populate the data and columns
        this.receivedData = updatedData.data || [];
        this.columns = Object.assign([], updatedData.columns) || [];

        // Precompute the styles and classes
        this.receivedData.forEach(row => {
          this.columns.forEach(column => {
            // Calculate cell classes for each column in the row
            row[`${column.columnDef}_classes`] = this.getCellClasses(column, row);

            // Precompute both heatmap and non-heatmap background colors
            row[`${column.columnDef}_heatmap_style`] = {
              'background-color': row[column.columnDef + '_heatmap_color'] || ''
            };

            row[`${column.columnDef}_non_heatmap_style`] = {
              'background-color': ''
            };
          });
        });

        // Set the dataSource after all precomputations
        this.dataSource = new MatTableDataSource(this.receivedData.filter(row => row.show));
        this.displayedColumns = this.columns.map(x => x.columnDef);

        // Sort data because the number of rows changed
        this.sortData(this.sort);

        // Trigger UI update as we need to render rows
        this.changeDetector.markForCheck();
      }),
      
      this.dataTableService.selectedTrendUpdated$.subscribe(selectedTrendMetrics => {
        this.applyTableTrendUpdate(selectedTrendMetrics);
      }),
      this.dataTableService.printButtonClicked$.subscribe(data => {
        if (data.printingOption === Enums.printingOptions.pdf) {
          console.log('PDF exporting is not supported in hierarchyDataTable');
        } else {
          this.filterService.requestBreadcrumbString();
          this.excel(data.title, data.expandAll);
        }
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort: Sort) => {
      this.sortData(this.sort);
    });
  }

  ngOnChanges() {
  }

  ngOnDestroy(): void {
    this.dataSet = null;
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // configureColumns(columns: Models.Column[]): Models.Column[] {
  //   columns.forEach(col => {
  //     col.show = true;
  //   });

  //   return columns;
  // }

  applyTableTrendUpdate(selectedTrendMetrics: string[]): void {
    this.showMom = selectedTrendMetrics.includes('MOM');
    this.showYoy = selectedTrendMetrics.includes('YOY');
    this.columns.filter(col => (col.isMom)).forEach(col => { col.show = this.showMom; });
    this.columns.filter(col => (col.isYoy)).forEach(col => { col.show = this.showYoy; });

    this.changeDetector.detectChanges();
  }

  updateReport(row) {
    const entity = row.entity;
    const entityType = row.entityType;
    const entityName = row.displayName;
    const orgFilterValue: OrgFilterValue = { levelTypeId: this.orgLookupTypeId, entityType: entityType, value: entity, display: entityName };

    this.drillThroughChange.emit(orgFilterValue);
  }

  toggleChildRows(clickedRow: any): void {
    this.dataTableService.toggleChildRows(clickedRow, this.receivedData, this.columns);
  }
  customTrackBy(index, item) { return item.key; }

  private excel(title: string, expandAll: boolean = false): void {
    let data: string[][] = [[title]];

    // data.push(['Filters:', this.filterBreadcrumbsForExport]);
    // data.push(['Filters:']);

    (this.filterBreadcrumbsForExport || []).forEach(bc => {
      data.push([bc]);
    });

    const cols = this.columns.filter(col => ((col.print === undefined && col.show)
      || (col.print && this.showDealerNameColumn(expandAll))
      || (!this.dealerNameEnabled() && col.print)))
      .map(col => col.header);

    data.push(['']);
    data.push(cols);
    data = data.concat(this.getRowsForExport(expandAll, title));

    const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

    new ExportCsv(data, printTitle);
  }

  private getRowsForExport(expandAll: boolean = false, title: string): string[][] {
    const results: string[][] = [];

    const columns = this.columns.filter((col) => {
      if (((col.print === undefined && col.show) || (col.print && this.showDealerNameColumn(expandAll)) || (!this.dealerNameEnabled() && col.print))) {
        return true;
      } else {
        return false;
      }
    });

    const rowData = !expandAll ? this.dataSource.filteredData : this.receivedData;
    const isDealer = this.configService.role.isDealerRole(this.filterModel.roleLevel);

    rowData.forEach(dataRow => {
      const exportRow: string[] = [];
      columns.forEach(col => {
        // Show entity display name in first column - except for dealers
        if (col.columnDef === 'entity') {
          if (dataRow['entityType'] !== 'dealer') {
            col.printFormatter ? exportRow.push(this.labelTranslator(col.printFormatter(dataRow['displayName']))) : exportRow.push(this.labelTranslator(dataRow['displayName'] || ''));
          } else {
            if (this.filterModel.dealerCode === null)
              exportRow.push(dataRow['entity']);
            else
              exportRow.push('');
          }
        } else if (this.dealerNameEnabled() && col.columnDef === 'displayName') {
          if (dataRow['entityType'] === 'dealer') {
            col.exportFormatter ? exportRow.push(col.exportFormatter(dataRow['displayName'])) : exportRow.push(dataRow['displayName'] || '');
          } else {
            exportRow.push('');
          }
        } else {
          col.printFormatter
            ? exportRow.push(col.printFormatter(dataRow[col.columnDef]))
            : col.formatter
              ? exportRow.push(col.formatter(dataRow[col.columnDef]))
              : exportRow.push(dataRow[col.columnDef] || '');
          //col.formatter ? exportRow.push(col.formatter(dataRow[col.columnDef])) : exportRow.push(dataRow[col.columnDef] || '');
        }
      });
      results.push(exportRow);
    });

    return results;
  }

  private showDealerNameColumn(expandAll: boolean = false): boolean {
    const filteredDataDealerExists = !!this.dataSource.filteredData.find(x => (x.entityType === 'dealer' && x.show === true));

    return this.dealerNameEnabled() && ((expandAll || filteredDataDealerExists));
  };

  private dealerNameEnabled(): boolean {
    const currentUrl = this.router.url;
    const urlsWithDealerNameEnabled: string[] = [
      '/inventory/overview',
      '/leads/overview',
      '/provider-tools',
      '/leads/summary',
      '/sales/summary',
      '/sales/vehicle',
      '/website/aftersales',
      '/website/chat',
      '/website/landingpage',
      '/website/overview',
      '/website/summary',
    ];

    return !!urlsWithDealerNameEnabled.find(u => u?.toUpperCase().includes(currentUrl?.toUpperCase()));
  }

  calculateClasses(column: IDefaultTableColumn, classes?: string[]) {

    // Classes passed in from the front end
    let manualClasses = {
      'hideRow': !column.show
    };

    (classes || []).forEach(mc => {
      manualClasses[mc] = true;
    })

    // Classes from the column config
    let configuredClasses = {};
    (column.columnClasses || []).forEach(cc => {
      configuredClasses[cc] = true;
    })
    // Anything passed into the function directly should be seen as a default that is overridable by configuration
    return {
      ...manualClasses,
      ...configuredClasses
    };
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    const data = this.dataSource.data;
    //console.log('sortData', data);

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.comparerService.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  getCellClasses(column: any, row: any): { [key: string]: boolean } {
    const baseClasses = this.calculateClasses(column, ['first-data-column']);
    return {
      'heatmap-td': this.heatmapEnabled,
      ...baseClasses
    };
  }  

  private updateHeatmapState() {
    this.heatmapEnabled = this.options?.some(o => o.name === tableOptions.Heatmap && o.enabled) || false;
    // Need to rerender rows with different background color
    // so let's trigger change detection
    this.changeDetector.markForCheck();
  }

  getBackgroundColor(row: any, column: any, heatmapEnabled: boolean): string {
    const heatmapColor = row[column.columnDef + '_heatmap_color'];
  
    // If heatmap color is grey, it means it is a total row, just return grey
    if (heatmapColor === '#f0f0f0') {
      return '#f0f0f0';
    }
  
    // Apply heatmap if enabled, otherwise non-heatmap style
    return heatmapEnabled ? row[column.columnDef + '_heatmap_style']?.['background-color'] : row[column.columnDef + '_non_heatmap_style']?.['background-color'];
  }  
}
